var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin',[_c('metatag',{attrs:{"title":"Inquiries"}}),_c('page-header',{scopedSlots:_vm._u([{key:"action",fn:function(){return undefined},proxy:true}])}),_c('v-card',[_c('toolbar-menu',{ref:"toolbar",attrs:{"items":_vm.tabletoolbar},on:{"update:items":function($event){_vm.tabletoolbar=$event},"update:search":_vm.setSearch}}),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.resources.headers,"items":_vm.resources.data,"loading":_vm.resources.loading,"items-per-page":5,"server-items-length":_vm.resources.total,"options":_vm.resources.options,"color":"primary","item-key":"id"},on:{"update:options":function($event){return _vm.$set(_vm.resources, "options", $event)}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":"primary","icon":"","to":{ name: 'dealerParts.cart.inquiries.admin.preview', params: { id: item.id } }}},on),[_vm._v(" "+_vm._s(item.id)+" ")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Preview')}})])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(item.email))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(item.mobile_number)+" ")])]}},{key:"item.total_amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatPrice(item.total_amount)))]),_vm._v(" "),_c('br')]}},{key:"item.primary_dealer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.primary_dealer) ? item.primary_dealer.name : '')+" ")]}},{key:"item.secondary_dealers",fn:function(ref){
var item = ref.item;
return _vm._l((item.secondary_dealers),function(dealer,i){return _c('small',{key:i,staticStyle:{"font-size":"80% !important"}},[_vm._v(" "+_vm._s(dealer.name)+" "),(i != Object.keys(item.secondary_dealers).length - 1)?_c('span',[_vm._v(",")]):_vm._e(),(i != Object.keys(item.secondary_dealers).length - 1)?_c('br'):_vm._e()])})}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }