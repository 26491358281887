<template>
    <admin>
      <metatag title="Inquiries"></metatag>
  
      <!-- redirect to upload form -->
      <page-header>
        <template v-slot:action>
        </template>
      </page-header>
      <v-card>
        <toolbar-menu
          ref="toolbar"
          :items.sync="tabletoolbar"
          @update:search="setSearch"
        ></toolbar-menu>
        <v-card-text class="pa-0">
          <v-data-table
            :headers="resources.headers"
            :items="resources.data"
            :loading="resources.loading"
            :items-per-page="5"
            :server-items-length="resources.total"
            :options.sync="resources.options"
            color="primary"
            item-key="id"
            class="text-no-wrap"
          >
            <template v-slot:item.id="{ item }">
              <div class="text-no-wrap">
                <!-- Preview -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn  
                    text 
                    color="primary" 
                    icon 
                    v-on="on" 
                    :to="{ name: 'dealerParts.cart.inquiries.admin.preview', params: { id: item.id } }"
                    >
                      {{ item.id }}
                    </v-btn>
                  </template>
                  <span v-text="'Preview'"></span>
                </v-tooltip>
              </div>
            </template>

            <template v-slot:item.name="{ item }">
                <span>{{ item.first_name }} {{ item.last_name }}</span> <br />
                <span>{{ item.email }}</span> <br />
                <span>{{ item.mobile_number }} </span>
            </template>

            <template v-slot:item.total_amount="{ item }">
              <span>{{ formatPrice(item.total_amount) }}</span> <br />
            </template>

            <template v-slot:item.primary_dealer="{ item }">
              {{ (item.primary_dealer) ? item.primary_dealer.name : '' }}
            </template>

            <template v-slot:item.secondary_dealers="{ item }">
              <small style="font-size: 80% !important;" v-for="(dealer, i) in item.secondary_dealers" :key="i">
                {{ dealer.name }}
                <span v-if="i != Object.keys(item.secondary_dealers).length - 1">,</span>
                <br v-if="i != Object.keys(item.secondary_dealers).length - 1">
              </small>
            </template>
           
  
          </v-data-table>
        </v-card-text>
      </v-card>
  
     
    </admin>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import * as helpers from "@/core/helpers";
  import { debounce } from "lodash";
  export default {
    data() {
      return {
        filters: [
          { text: "Model", value: "model" },
          { text: "Variant", value: "name" },
          { text: "Year", value: "year" },
          { text: "Price", value: "price" },
          { text: "Status", value: "status" },
        ],
        tabletoolbar: {
          isSearching: false,
          search: null,
        },
        resources: {
          loading: true,
          headers: [
            {
              text: "ID",
              value: "id",
              align: "left"
            },
           
            {
              text: "Customer",
              value: "name",
              align: "left"
            },
            {
              text: "Primary Dealer",
              value: "primary_dealer",
              align: "left"
            },
            {
              text: "Secondary Dealers",
              value: "secondary_dealers",
              align: "left"
            },
    
            {
              text: "Total Quantity",
              value: "total_quantity",
              align: "left"
            },
            {
              text: "Total Amount",
              value: "total_amount",
              align: "left"
            },
            {
              text: "Created At",
              value: "created_at",
              align: "left"
            }
          ],
          options: {},
          data: [],
        },
        inquiryDialog : false,
        previewDialog : false,
        filterData: {
          q: "",
        },
       
      };
    },
    watch: {
      "resources.options": {
        handler() {
          const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
          this.getItems(page, itemsPerPage, this.filterData);
        },
        deep: true,
      },
    },
    computed: {
      ...mapGetters({
        baseInquiries : "dealerPartInquiries/GET_BASE_INQUIRIES",
      }),
    },
    methods: {
      ...mapActions({
        getBaseInquiries : "dealerPartInquiries/getBaseInquiries",
      }),
  
      setSearch: debounce(async function (e) {
        const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
        this.filterData.q = e.target.value;
        this.getItems(page, itemsPerPage, this.filterData);
      }, 300),
  
      getItems(page, itemsPerPage, filterData = {}) {
        let data = {
          page,
          per_page: itemsPerPage,
          filters : filterData,
        };

        
        this.getBaseInquiries(data).then(() => {
            this.resources.data = this.baseInquiries.data;
            this.resources.total = this.baseInquiries.meta.total;
            this.resources.loading = false;
            this.$refs.toolbar.items.isSearching = false;
        });
      },
      formatDate(item, format) {
        return helpers.format_date(item, format);
      },
  
      formatPrice(item) {
        return helpers.format_price(item);
      },
    },
  };
  </script>
  
  <style></style>
  